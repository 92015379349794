@import "../../../shared/Helpers/colors.scss";

.container {
  display: flex;
  background-color: $white100;
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  min-width: 720px;
  margin: 0 auto 16px auto;

  .bcbaInfo {
    width: 30%;
    padding: 0 8px;

    .bcbaName {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;

      .editIcon {
        width: 32px;
        height: 32px;
        background-color: $blue500;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-radius: 5px;
      }
    }
  }

  .latesNotes {
    width: 50%;

    .note {
      padding: 8px;
    }
  }

  .clientList {
    width: 50%;
    padding: 0 8px;
  }
}

@media (max-width: 820px) {
  .container {
    flex-direction: column;
    min-width: 220px;
    background-color: transparent;
    padding: 0;

    .bcbaInfo {
      padding: 0;
      width: 100%;
    }

    .latesNotes {
      width: 100%;

      .notesContainer {
        flex-direction: column;

        .note {
          width: 100%;
        }
      }
    }

    .clientList {
      width: 100%;

      .tableContainer {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
      }
    }
  }
}
