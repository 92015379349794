.btns-block {
  display: flex;
  gap: 7px;

  button {
    background-color: transparent;
    outline: none;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    color: rgb(0, 0, 0);
    &:hover {
      background-color: rgb(104, 176, 188);
      color: rgb(255, 255, 255);
    }
  }
}

.description {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  justify-content: space-between;
  position: relative;
}
textarea {
  resize: none;
  width: 80%;
  height: 120px;
  border-radius: 16px;
  padding: 20px;
  border: 2px solid #e7e8e9;
}
textarea:focus-visible {
  outline: none;
}
.date-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  justify-content: space-between;

  input[type="date"] {
    border: 2px solid #e7e8e9;
    width: 80%;
    height: 30px;
  }
}
.btns-popup {
  display: flex;
  gap: 10px;
}
.container-view {
  display: flex;
  flex-direction: column;
  .info-annotation {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    align-items: center;
    gap: 10px;
  }
}
