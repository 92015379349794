.position-block-date {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 20px;
  align-items: flex-start;
}
.filter-block {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  gap: 20px;
  align-items: flex-start;

  label {
    align-self: flex-start;
  }
}
