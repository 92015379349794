@import "../shared/Helpers/colors";
@import "../shared/Helpers/spaceElements.scss";

* {
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

a {
  color: $black900;
  text-decoration: none;
}

.menuLink {
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 4px;
  margin-bottom: 4px;
  cursor: pointer;
  user-select: none;
  line-height: 24px;
}

.breadcrampLink {
  font-weight: 700;
}

.overflowEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title {
  border-bottom: 2px solid $blue400;
  padding-bottom: 8px;
}

.ant-picker {
  border: 1px solid $black500;
  height: 40px;
  border-radius: 10px;
  width: 100%;
}

.blueBorderBottom {
  border-bottom: 2px solid $blue400;
}

.redBorderBottom {
  border-bottom: 2px solid $red300;
}

.width60 {
  width: 60px;
}

.width100 {
  width: 100px;
}

.width150 {
  width: 150px;
}

.button150 {
  width: 150px;
  height: 150px;

  button {
    height: 150px;
    font-size: 48px;
  }
}

.textAlignCenter {
  text-align: center;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.ant-picker-panel-layout {
  & > div {
    width: 100%;
  }
}

.ant-picker-footer {
  display: none;
}

.ant-picker-dropdown .ant-picker-time-panel-column::after {
  display: none;
}

.ant-picker-dropdown {
z-index: 1100;
}

.MuiListItem-root {
  .MuiListItemButton-root.Mui-selected {
    background-color: $red050;

    .menuLink {
      > div {
        color: $white100;
      }
    }
  }
}

.prompts {
  span {
    font-size: 1.5em;
  }
}

.blink {
  animation: blink-animation 3s linear infinite;
}

.billableWrapper {
  display: flex;
  justify-content: space-between;

  .billableUserWrapper {
    width: max-content;
    display: flex;
    align-items: center;
    gap: 16px;
    border: 2px solid $red300;
    padding: 8px;
    border-radius: 12px;
    margin-right: 64px;
  }
}

.btnContainer {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;

  .filterBlock {
    display: flex;
    align-items: center;

    .btnFilter {
      border: none;
      border-left: 1px solid grey;
      font-size: 13px;
      color: #396f93;
      display: flex;
      align-items: center;
      padding: 0 5px;
      cursor: pointer;
      font-weight: 600;
      height: 20px;
      background-color: transparent;

      &:hover {
        color: #020e17;
      }
      &:active {
        color: #020e17;
      }
    }
    .activeBtn {
      color: #020e17;
    }
  }
}

.MuiTabs-flexContainer {
  flex-wrap: wrap;
}

@keyframes blink-animation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes blink-animation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media (max-width: 500px) {
  .billableWrapper {
    width: 100%;
    flex-direction: column;
    .billableUserWrapper {
      width: 100%;
    }
  }
}
