@import "../../Helpers/colors";

.tableContainer {
  background-color: $white100;

  .tableHeader {
    th {
      border-bottom: 1px solid $blue400;

      &:last-child {
        div {
          border-right: none;
        }
      }

      
    }
  }
}

.nameTitleFilter {
  position: relative;
  border-right: 1px solid $black900;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .filterWrapper {
    position: absolute;
    top: -15px;
    left: -10px;
    background-color: white;
    width: 100%;
    border-radius: 12px;
    border: 2px solid $blue400;
    padding: 10px;
    z-index: 1000;
  }
}

.nameTitle {
  border-right: 1px solid $black900;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 16px;
  }
}