@import "../../../../../../../shared/Helpers/colors.scss";

.btnContainer {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;

  .filterBlock {
    display: flex;
    align-items: center;

    .btnFilter {
      border: none;
      border-left: 1px solid grey;
      font-size: 13px;
      color: #396f93;
      display: flex;
      align-items: center;
      padding: 0 5px;
      cursor: pointer;
      font-weight: 600;
      height: 20px;
      background-color: transparent;

      &:hover {
        color: #020e17;
      }
      &:active {
        color: #020e17;
      }
    }
    .activeBtn {
      color: #020e17;
    }
  }
}
