.marginAuto {
    margin: auto
}

.margin8 {
    margin: 8px
}

.margin16 {
    margin: 16px
}

.margin24 {
    margin: 24px
}

.margin32 {
    margin: 32px
}

.margin40 {
    margin: 40px
}

.marginBottom8 {
    margin-bottom: 8px
}

.marginBottom16 {
    margin-bottom: 16px
}

.marginBottom24 {
    margin-bottom: 24px
}

.marginBottom32 {
    margin-bottom: 32px
}

.marginBottom40 {
    margin-bottom: 40px
}

.marginTop8 {
    margin-top: 8px
}

.marginTop16 {
    margin-top: 16px
}

.marginTop24 {
    margin-top: 24px
}

.marginTop32 {
    margin-top: 32px
}

.marginTop40 {
    margin-top: 40px
}

.marginLeft8 {
    margin-left: 8px
}

.marginLeft16 {
    margin-left: 16px
}

.marginLeft24 {
    margin-left: 24px
}

.marginLeft32 {
    margin-left: 32px
}

.marginLeft40 {
    margin-left: 40px
}

.marginRight8 {
    margin-right: 8px
}

.marginRight16 {
    margin-right: 16px
}

.marginRight24 {
    margin-right: 24px
}

.marginRight32 {
    margin-right: 32px
}

.marginRight40 {
    margin-right: 40px
}


.padding8 {
    padding: 8px
}

.padding16 {
    padding: 16px
}

.padding24 {
    padding: 24px
}

.padding32 {
    padding: 32px
}

.padding40 {
    padding: 40px
}

.paddingBottom8 {
    padding-bottom: 8px
}

.paddingBottom16 {
    padding-bottom: 16px
}

.paddingBottom24 {
    padding-bottom: 24px
}

.paddingBottom32 {
    padding-bottom: 32px
}

.paddingBottom40 {
    padding-bottom: 40px
}

.paddingTop8 {
    padding-top: 8px
}

.paddingTop16 {
    padding-top: 16px
}

.paddingTop24 {
    padding-top: 24px
}

.paddingTop32 {
    padding-top: 32px
}

.paddingTop40 {
    padding-top: 40px
}

.paddingLeft8 {
    padding-left: 8px
}

.paddingLeft16 {
    padding-left: 16px
}

.paddingLeft24 {
    padding-left: 24px
}

.paddingLeft32 {
    padding-left: 32px
}

.paddingLeft40 {
    padding-left: 40px
}

.paddingRight8 {
    padding-right: 8px
}

.paddingRight16 {
    padding-right: 16px
}

.paddingRight24 {
    padding-right: 24px
}

.paddingRight32 {
    padding-right: 32px
}

.paddingRight40 {
    padding-right: 40px
}