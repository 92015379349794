@import "../../../../../shared/Helpers/colors.scss";

.targetsMobile {
    display: none;
}

.actions {
    display: flex;
    justify-content: space-around;
}

.targetInfo {
    display: flex;
    width: 100%;

    >div {
        width: 100%
    }
}

#upload-excel-file {
    display: none;
}

@media (max-width:1024px) {
    .targetsDesktop {
        display: none;
    }

    .targetsMobile {
        display: flex;
        flex-direction: column;
    }

    .actions {
        flex-direction: column;

        >div {
            margin-bottom: 8px;
        }

        :nth-child(2n) {
            >button {
                background-color: $white100;
                color: $blue400;
            }
        }
    }

    .targetsMobileContainer {
        width: 100%;
    }

    .targetInfo {
        flex-direction: column;
        width: 100%;

        >div {
            border-left: 2px solid $black500
        }
    }
}