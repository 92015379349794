// blue
$blue100: #cceaf0;
$blue400: #68B0BC;
$blue500: #72A0C1;
$blue700: #6693BC;

// red
$red050: #ffccbd;
$red100: #F2BDAD;
$red500: #E5837A;
$red300: #FE6F5E;
$red800: #FF665B;
$red900: #FF6347;

// green
$green200: #90BA3C;
$green400: #39642F;

//black
$black500: #212121;
$black900: #000000;

//white
$white100: #FFFFFF;
$white200: #FFF7E1;
$gray100: #f1f1f1;