@import "../../shared/Helpers/colors.scss";

.calendarHeader {
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .date {
    display: flex;
    align-items: center;
  }
}

.overflowText {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.yearView {
  .headers {
    display: flex;
    margin-bottom: 8px;

    .header {
      width: 13%;
      height: 50px;
      display: flex;
      align-items: center;
      margin-right: 4px;
      justify-content: space-around;
    }
  }

  .daysWrapper {
    display: flex;
    flex-wrap: wrap;
    height: max-content;

    .day {
      width: 13%;
      min-height: 150px;
      margin: 0.4%;
      padding: 0.5%;
      border-radius: 8px;

      .dayHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        margin-bottom: 8px;
      }

      .sessionWrapper {
        .session {
          padding: 6px;
          margin: 4px;
          border-radius: 8px;
          cursor: pointer;
          position: relative;

          &:hover {
            .tooltip {
              display: block;
              position: absolute;
              width: max-content;
              bottom: 100%;
              right: 10%;
              background-color: $white100;
              padding: 8px;
              border-radius: 12px;
              border: 1px solid $black500;
            }
          }

          .tooltip {
            display: none;

            &::after {
              content: "";
              position: absolute;
              top: 100%;
              right: 20%;
              margin-left: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: black transparent transparent transparent;
            }
          }
        }
      }

      .sessionWrapperMobile {
        display: none;
      }
    }
  }
}

.sessionWeek {
  min-width: 100px;
  max-width: 428px;
  position: relative;
  padding: 6px;
  margin: 4px;
  border-radius: 8px;
  cursor: pointer;
  
  &:hover {
    .tooltip {
      display: block;
      position: absolute;
      width: max-content;
      bottom: 100%;
      right: 10%;
      background-color: $white100;
      padding: 8px;
      border-radius: 12px;
      border: 1px solid $black500;
    }
  }

  .tooltip {
    display: none;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      right: 20%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }
}

.dayWeek {
  display: flex;
  min-height: 100px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 8px;
}

@media (max-width: 426px) {
  .yearView {
    display: none;
  }
}