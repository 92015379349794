@import "../../Helpers/colors";

.error {
  opacity: 0;
  height: 0px;
  transition: all 0.5s ease-out 0s;
  line-height: 16px;
  display: flex;
  justify-content: left;
  padding-left: 8px;
  font-size: 14px;

  &.active {
    opacity: 1;
    height: 16px;
  }
}

.inputLabel {
  display: flex;
  justify-content: left;
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  color: $black900;
  padding-left: 8px;
  opacity: 0;
  height: 0px;

  &.active {
    margin-bottom: 8px;
    opacity: 1;
    height: 16px;
  }
}

.inputDescription {
  opacity: 0;
  height: 0px;
  transition: all 0.5s ease-out 0s;
  color: $black900;
  line-height: 16px;
  display: flex;
  justify-content: left;
  padding-left: 8px;
  font-size: 14px;
  white-space: pre-wrap;

  &.active {
    opacity: 1;
    height: max-content;
    min-height: 16px;
  }
}

.customPhoneInput {
  width: 100%;
  border: 1px solid;
  height: 40px;
  padding: 0 20px;
  background: $white100;
  border-color: $black500;
  border-bottom: 1px solid $black900;
  border-radius: 10px !important;
  text-transform: none;
  display: inline-flex !important;

  select {
    width: 100%;
    text-transform: none;
    background: $white100;
    color: $black900;
    border-color: $black500;
    height: 40px;
    padding: 0 20px;
  }

  input {
    border: none;
    font-size: 16px;
    color: $black900;

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: $black500;
    }
  }
}

.addressLine {
  width: 100%;
  border: 1px solid;
  border-radius: 10px !important;
  text-transform: none;
  background: $white100;
  color: $black900;
  border-color: $black900;
  height: 40px;
  padding: 0 20px;
  font-family: Poppins;
  border-bottom: 1px solid $black900;
  font-size: 14px;
  margin-bottom: 16px;
  position: relative;

  input {
    width: 100%;
    border: none;
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    margin: 0;
    padding: 6px 0 7px;
    background: none;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
    height: 40px
    ;
    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: $black900;
    }
  }
}
