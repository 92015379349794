@import "../../../../../../../../shared/Helpers/colors.scss";

// download annotations styles
.display-none {
  position: absolute;
  display: none;
  height: 500px;
}

.content-annotation {
  padding: 20px;
  width: 420px;

  h2 {
    font-size: 0.9em;
    line-height: 1em;
  }

  h3 {
    margin: 5px 0;
    font-size: 0.7em;
    line-height: 0.8em;

    span {
      font-size: 85%;
      line-height: 0.8em;
      color: #092a4a;
    }
  }

  .wrapper-list{
    padding: 8px 0;
  }

  .wrapper-list,
  .body-block {
    display: flex;
    flex-direction: column;

    .title-block,
    .item-block {
      display: grid;
      grid-template-columns: 3fr 1fr;
      gap: 10px;
      width: 100%;
      height: 100%;

      h4 {
        font-weight: 700;
        font-size: 0.5em;
      }

      p {
        font-size: 0.5em;
      }
    }
  }
}